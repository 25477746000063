.filter-gradient.blue::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background: rgba(54, 151, 142, 0.8);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #1e5799 0%,
    rgba(54, 151, 142, 0.8) 0%,
    #0c526c 100%,
    #3d7eaa 100%,
    #182848 100%,
    #6e48aa 100%,
    #6e48aa 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #1e5799),
    color-stop(0%, rgba(54, 151, 142, 0.8)),
    color-stop(100%, #0c526c),
    color-stop(100%, #3d7eaa),
    color-stop(100%, #182848),
    color-stop(100%, #6e48aa),
    color-stop(100%, #6e48aa)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #1e5799 0%,
    rgba(54, 151, 142, 0.8) 0%,
    #0c526c 100%,
    #3d7eaa 100%,
    #182848 100%,
    #6e48aa 100%,
    #6e48aa 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #1e5799 0%,
    rgba(54, 151, 142, 0.8) 0%,
    #0c526c 100%,
    #3d7eaa 100%,
    #182848 100%,
    #6e48aa 100%,
    #6e48aa 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #1e5799 0%,
    rgba(54, 151, 142, 0.8) 0%,
    #0c526c 100%,
    #3d7eaa 100%,
    #182848 100%,
    #6e48aa 100%,
    #6e48aa 100%
  );
  background: radial-gradient(
    ellipse at center,
    #1e5799 0%,
    rgba(54, 151, 142, 0.8) 0%,
    #0c526c 100%,
    #3d7eaa 100%,
    #182848 100%,
    #6e48aa 100%,
    #6e48aa 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(54, 151, 142, 0.8)', endColorstr='#6e48aa', GradientType=1);
  z-index: -1;
}

@media (max-width: 720px) {
  .filter-gradient.blue::after {
    height: 100%;
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}
